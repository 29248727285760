
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'

import { FormPage } from '@/mixins'
import { InputDateTime, InputText, InputTextarea } from '@/components/inputs'
// import { OfferCreateDto, OfferStatus } from 'tradingmate-modules/src/models/api/offers'
import ContentHtmlInput from '@/components/inputs/ContentHtmlInput.vue'
import BlogType from '../../../../../tradingmate-modules/src/models/api/blogs/BlogType'
import CreateBlogModel from '../../../../../tradingmate-modules/src/models/api/blogs/CreateBlogModel'
import BlogPostStatus from '../../../../../tradingmate-modules/src/models/api/blogs/BlogPostStatus'

@Component({
  components: {
    ContentHtmlInput,
    InputText,
    InputDateTime,
    InputTextarea
  }
})
export default class OfferCreate extends Mixins(FormPage) {
  private readonly dateConfig = { minDate: Date.now() }

  private offerCreateDto: CreateBlogModel = {
    BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId,
    Status: BlogPostStatus.Draft,
    Heading: '',
    Subheading: '',
    ExternalLink1: '',
    ExternalLink2: '',
    Content: '',
    ExpirationDate: undefined,
    BlogType: BlogType.Offer
  }

  savePost (): void {
    this.loading = true

    Services.API.Blogs.CreateBlog(this.offerCreateDto)
      .then((offer) => {
        this.httpError = null
        this.$router.replace(`/offer/${offer.Slug}`)
      }).catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.loading = false
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      })
  }
}
